body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #1a1a1a; /* dark background */
  color: #f5f5f5; /* light text */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: #111; /* even darker background for header */
  color: #f5f5f5;
  text-align: center;
  padding: 60px 0;
}

h1 {
  margin: 0;
}

p {
  margin: 20px 0;
}

.btn {
  display: inline-block;
  background-color: #e91e63; /* magenta-pink from the image */
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.btn:hover {
  background-color: #c2185b; /* slightly darker pink for hover */
}

.feature {
  margin: 40px 0;
  border-left: 3px solid #e91e63; /* adding a pink line to the left for features */
  padding-left: 15px;
}

footer {
  text-align: center;
  margin-top: 40px;
  padding: 20px 0;
  background-color: #111;
  color: #f5f5f5;
}
